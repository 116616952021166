*
{
    margin: 0;
    padding: 0;
    /* Internet Explorer 10+ */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;
}

/* * {
    outline: 1px solid red;
} */

/* Safari and Chrome */
*::-webkit-scrollbar {
    display: none;
}

html
{
    background: #DBD6CE;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

.navigator {
    display: none;
}

#mainContainer {
    margin-left: 0;
    grid-area: main;
    margin-bottom: 2rem;
    /* overflow-y: scroll; */
}

@media (min-width: 1000px) {
    .navigator {
        font-family: ShipleyRegular;
        letter-spacing: .25em;
        width: 140px;
        margin-left: 0.5px;
        border-right: 2px solid rgba(164,159,152,.30);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-right: 8px;

        height: 100%; /* Full-height: remove this if you want "auto" height */
        position: fixed; /* Fixed Sidebar (stay in place on scroll) */
        z-index: 1; /* Stay on top */
        top: 0; /* Stay at the top */
        left: 0;
        /* overflow-x: hidden; Disable horizontal scroll */
    }

    #mainContainer {
        margin-left: 150.5px;
    }
}

.section
{
    font-family: ShipleyRegular;
    display: flex;
    flex-direction: column;
    position: relative;
    color: #3E4040;
    font-size: 2rem;
    padding-left: 5%;
    padding-right: 5%;
}

.section p, li {
    font-size: 4vmin;
    width: 100%;
}


.section:nth-child(1) {
    justify-content: center;
}

@media (min-width: 700px) {
    .section {
        font-size: 4rem;
        padding-left: 10%;
        padding-right: 10%;
    }

    .section p, li {
        font-size: 1.5rem;
        width: 70%;
    }
}

.navigator-link-container {
    display: flex;
    flex-direction: column;
}

.navigator-external-link-container {
    display: flex;
    justify-content: space-evenly;
}

.caption {
    padding-left: 8px;
    margin-top: 64px;
    opacity: .5;
    text-align: right;
    margin-left: 0.1em;
    /* transition: opacity .625s; */
}

#dot {
    position: absolute;
    top: 65px;
    left: 141px;
    height: 16px;
    width: 16px;
    background-color: #a0a795;
    border-radius: 50%;
    display: inline-block;
}

#emmaherms {
    height: 100vh;
}

#sectionsContainer {
    display: grid;
    grid-template-columns: auto;
    /* grid-template-rows: repeat(4, 1fr); */
    row-gap: 15rem;
}

@media (min-width: 700px) {
    #sectionsContainer {
        margin-top: 0;
        margin-bottom: 0;
        row-gap: 35rem;
    }
}

ol {list-style-position: inside;}

.navigator a {
    text-decoration: none; /* no underline */
    color: #3E4040;
}

.publicationSection {
    width: 90%;
    margin-top: 8px;
    display:flex;
    flex-direction: column;
}

.blogSection {
    margin-top: 32px;
    margin-bottom: 32px;
}

.blogSection p {
    width: 100%;
}

.blogSection a, .publicationSection a, h6 {
    color: #A49F98;
}

p {
    margin-top: 16px;
}

.ongoingProjectsSection {
    margin-top: 32px;
}

.flex-item-column {
    display:flex;
    flex-direction: column;
}

.blogGrid {
    display: grid;
    grid-template-columns: 92% 8%;
    grid-gap: 10px;
}

.blogGrid img {
    height: auto;
    width: 100px;
    padding-top: 8px;
    display: none;
}

@media (min-width: 700px) {
    .blogGrid img {
        display: block;
    }
}
